@import "variables";
@import "generic";

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00AEEB;
  --bs-btn-border-color: #00AEEB;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #009CDF;
  --bs-btn-hover-border-color: #009CDF;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #009CDF;
  --bs-btn-active-border-color: #009CDF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00AEEB;
  --bs-btn-disabled-border-color: #00AEEB;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #28A745;
  --bs-btn-border-color: #28A745;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #218838;
  --bs-btn-hover-border-color: #218838;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #218838;
  --bs-btn-active-border-color: #218838;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #28A745;
  --bs-btn-disabled-border-color: #28A745;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #DC3545;
  --bs-btn-border-color: #DC3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #C82333;
  --bs-btn-hover-border-color: #C82333;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #C82333;
  --bs-btn-active-border-color: #C82333;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #DC3545;
  --bs-btn-disabled-border-color: #DC3545;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFB600;
  --bs-btn-border-color: #FFB600;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFB600;
  --bs-btn-disabled-border-color: #FFB600;
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem #d63384;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #d63384;
  --bs-pagination-active-border-color: #d63384;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}



/****** LOGIN SCREEN
****************************************************************************************************************/
.login-info,.login-nav,.login-sec{
  .container{ max-width:1400px;}
}
.login-sec{ padding:0;
  background-size:cover; background-repeat:no-repeat; background-position:center;
  background-image: url('./assets/images/login-bg.jpg');
  .login-text{ font-size:40px; color:#FFF; margin:0;}
  .sub-text{ color:#FFF; margin:1em 0 0;}
  .spinner-border{ font-size:10px; width:18px; height:18px; vertical-align:middle;}
  .trademark-body{ max-width:1400px; margin:auto;}
  .forgot-formlabel .form-label{ justify-content:start!important;}
  @media screen and (max-width:1000px){
    .login-text{ font-size:30px;}
    .sub-text{ margin:.5em 0 1em;}
    .login-sub-text{margin-top:1.25em;}
  }
  .reset-formlabel .form-label{ justify-content:start!important;}
}


.page-link{ color:#000;}
.active>.page-link, .page-link.active{ background-color:$brand-primary; border-color:$brand-secondary;}
.prescribing-accordion{
  .accordion-item{ border:0;}
  .accordion-header{
    .accordion-button{ color:$brand-primary; background:#1F1F1F; border-radius:0 !important; justify-content:center;
      &:focus{ box-shadow:none;}
      &:after{ display:none;}
    }
  }
  @media screen and (max-width:1000px){
    .accordion-body{ max-height:450px; overflow:auto;}
  }
}

.cases-page{
  .table{
    thead{ position:sticky; top:0;}
  }
}

.case-creation-step{
  .checkbox-ui{ display:flex; margin:0 0 15px;
    .card{ padding:15px; cursor:pointer; box-shadow:5px 5px 5px #EEE; transition:0.2s all;
      h6{ transition:0.2s all;}
      &:hover{ border-color:$brand-primary;
        h6{ color:$brand-primary;}
      }
    }
    .form-check-input:checked ~ .card{ border-color:$brand-primary; box-shadow:var(--bs-box-shadow); overflow:hidden;
      h6{ color:$brand-primary;}
      &::before{ content:""; position:absolute; top:0; right:0; border-radius:0 0 0 10px;
        width:30px; height:30px; background-color:$brand-primary;
      }
      &::after{ content:"\F26F"; position:absolute; top:1px; right:5px; color:#FFF;
        font-family:bootstrap-icons; font-size:20px;
      }
    }
  }
}
