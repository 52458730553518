@font-face{
  font-family: "avanos-regular";
  src: url("./assets/fonts/AvanosSans-Regular.woff") format("woff");
}
@font-face{
  font-family: "avanos-semibold";
  src: url("./assets/fonts/AvanosSans-SemiBold.woff") format("woff");
}
html,body{ height:100%; display:flex; flex-direction:column;
  main{ display:flex; flex-direction:column; flex:1; overflow:hidden;}
}
body {
  font-family: "avanos-regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,h2,h3,h4,h5,h6{ font-family: "avanos-semibold", sans-serif;}

.form-label{ display:flex; align-items:center; justify-content:end; max-height:37px; margin:0; font-weight:bold;
  @media screen and (max-width:992px){
    justify-content:start; text-align:left; max-height:none;
  }
}

/****** TABLE
****************************************************************************************************************/
.smallTable,table,th,td{ font-size:14px;}
.table-striped>tbody>tr:nth-of-type(odd)>*{ box-shadow:none; background-color:#f6f6f6;}
.background-color-1{ background-color:#00AEEB !important;}
.background-color-2{ background-color:#0074BE !important;}
.background-color-3{ background-color:#28a745 !important;}
.background-color-4{ background-color:#d9534f !important;}
.background-color-5{ background:#FFB600 !important;}

/****** HEADER
****************************************************************************************************************/
header{ border-bottom:1px solid #DDD;
  .navbar-brand{
    img{ max-width:400px; width:100%;}
    @media screen and (max-width:992px){
      flex:1;
    }
  }
  .navbar-toggler{ padding:4px 8px; box-shadow:none !important;}
  .first-nav{
    li:nth-child(2){
      .nav-link{ background-color:#00AEEB; border-radius:100px; color:#FFF; padding:8px 15px;}
    }
  }
  .nav-link{ text-transform:uppercase; font-size:17px; font-weight:bold;}
}

/****** BUTTONS
****************************************************************************************************************/
.btn-primary{ border-color:$brand-secondary;
  background:linear-gradient(90deg,$brand-primary 0%,$brand-tertiary 100%);
}
.btn-success{--bs-btn-bg: #81BD41 !important;}

/****** BRANDING COLORS
****************************************************************************************************************/
.text-primary{ color:$brand-primary !important;}
.text-secondary{ color:$brand-secondary;}
.text-link{ color:$brand-primary;}
.bg-primary{ background-color:#006E9F !important;}
.bg-gradiant-primary{ background: linear-gradient(90deg,$brand-primary 0%,$brand-tertiary 100%);}

/****** CARDS
****************************************************************************************************************/
.card-header{ background-color:#F0F0F0;}



.case-progress-bar{ overflow:hidden; border-radius:7px; display:flex; background-color:#d3d3d3;
  .case-creation-progress-step{ background:transparent; position:relative;
    flex:1; border-radius:0; border:0; padding:6px 4%;
    &:hover{ background-color:#c4c4c4;}
    .progression-arrow-head{ color:transparent; font-size:70px; z-index:1;
      position:absolute; left:100%; top:0; bottom:0; display:flex; align-items:center;
      &:before{ text-indent:-22px;}
      @media screen and (max-width:768px){
         font-size:50px;
      }
    }
    &.active-progress-step{ background-color:$brand-primary; color:#FFF;
      .progression-arrow-head{ color:$brand-primary;}
    }
    &.invalid-progress-step{ background-color:$brand-secondary; color:#FFF;
      .progression-arrow-head{ color:$brand-secondary;}
    }
  }
}

.statusColor-dropdown{ min-width:400px;}
@media screen and (max-width:1000px){
  .mobile-dropdown{ max-height:230px; overflow-y:auto; overflow-x:hidden;}
  .statusColor-dropdown{ max-height:230px; overflow-y:auto; overflow-x:hidden; min-width:auto;}
}
