@import "variables";




.Hub-Footer-link {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.guest-page-bg {
  background: linear-gradient(90deg, rgba(255,158,27,1) 0%, rgba(251,99,126,1) 100%);
  min-height: 90vh;
}
.link-modal:hover {
  cursor: pointer;
  text-decoration: underline;
}
.smallText{
  font-size: 10px;
}
.smallHeight{
  height: 20px;
}
.btn-xs{
font-size: 8px !important;
}
.height-to-body {
  height: 100vh;
}





.case-detail-tab {
  background-color: var(--bs-primary) !important;
  color: white !important;
}

.footer-accordian {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-accordian::after {
  display: none;
}

.show-modal {
  display: block !important;
  backdrop-filter: brightness(0.3);
}

.loader {
  z-index: 1050 !important;
  height: 3.5px !important;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
}

.text-8{
  font-size: 8px !important;
}.text-9{
  font-size: 9px !important;
}.text-10{
  font-size: 10px !important;
}.text-11{
  font-size: 11px !important;
}.text-12{
  font-size: 12px !important;
}.text-13{
  font-size: 13px !important;
}.text-14{
  font-size: 14px !important;
}.text-15{
  font-size: 15px !important;
}.text-16{
  font-size: 16px !important;
}.text-17{
  font-size: 17px !important;
}.text-18{
  font-size: 18px !important;
}.text-19{
  font-size: 19px !important;
}.text-20{
  font-size: 20px !important;
}.text-21{
  font-size: 21px !important;
}.text-22{
  font-size: 22px !important;
}.text-23{
  font-size: 23px !important;
}
.font-weight-custom{
  font-weight: 900;
}

.dropdown-data {
  max-width: 75rem;
  max-height: 50vh;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  z-index: 2;
}

.height-animation {
  -moz-transition: height 1s ease-in-out;
  -webkit-transition: height 1s ease-in-out;
  -o-transition: height 1s ease-in-out;
  transition: height 1s ease-in-out;
}
.case-notes-listing {
  max-height: 244px !important;
  overflow: auto;
}

.btn-purple {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #61116A !important;
  --bs-btn-border-color: #61116A !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #611146;
  --bs-btn-hover-border-color: #611146 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #611146;
  --bs-btn-active-border-color: #611146;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #61116A;
  --bs-btn-disabled-border-color: #61116A;
}

.progress-bar-custom-animation {
  -moz-animation: widthanimate 15s infinite;
  -webkit-animation: widthanimate 15s infinite;
  -o-animation: widthanimate 15s infinite;
  animation: widthanimate 15s infinite;
  animation-timing-function: linear;
}

@keyframes widthanimate {
  0% { width: 0 }
  10% { width: 100% }
  20% { width: 0 }
  30% { width: 100% }
  40% { width: 0 }
  50% { width: 100% }
  60% { width: 0 }
  70% { width: 100% }
  80% { width: 0 }
  90% { width: 100% }
  100% { width: 0 }
}
